import A, { useLayoutEffect as xn, useEffect as N, useMemo as R, useRef as E, useCallback as P, useState as $, createContext as Ne, useContext as ae, memo as Sn, useReducer as ut, forwardRef as Cn, cloneElement as Rn } from "react";
import { unstable_batchedUpdates as xe, createPortal as En } from "react-dom";
const Ve = typeof window < "u" && typeof window.document < "u" && typeof window.document.createElement < "u";
function ge(e) {
  const t = Object.prototype.toString.call(e);
  return t === "[object Window]" || // In Electron context the Window object serializes to [object global]
  t === "[object global]";
}
function dt(e) {
  return "nodeType" in e;
}
function B(e) {
  var t, n;
  return e ? ge(e) ? e : dt(e) && (t = (n = e.ownerDocument) == null ? void 0 : n.defaultView) != null ? t : window : window;
}
function ft(e) {
  const {
    Document: t
  } = B(e);
  return e instanceof t;
}
function Te(e) {
  return ge(e) ? !1 : e instanceof B(e).HTMLElement;
}
function Vt(e) {
  return e instanceof B(e).SVGElement;
}
function pe(e) {
  return e ? ge(e) ? e.document : dt(e) ? ft(e) ? e : Te(e) || Vt(e) ? e.ownerDocument : document : document : document;
}
const J = Ve ? xn : N;
function qe(e) {
  const t = E(e);
  return J(() => {
    t.current = e;
  }), P(function() {
    for (var n = arguments.length, r = new Array(n), o = 0; o < n; o++)
      r[o] = arguments[o];
    return t.current == null ? void 0 : t.current(...r);
  }, []);
}
function On() {
  const e = E(null), t = P((r, o) => {
    e.current = setInterval(r, o);
  }, []), n = P(() => {
    e.current !== null && (clearInterval(e.current), e.current = null);
  }, []);
  return [t, n];
}
function Ee(e, t) {
  t === void 0 && (t = [e]);
  const n = E(e);
  return J(() => {
    n.current !== e && (n.current = e);
  }, t), n;
}
function Me(e, t) {
  const n = E();
  return R(
    () => {
      const r = e(n.current);
      return n.current = r, r;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...t]
  );
}
function Ye(e) {
  const t = qe(e), n = E(null), r = P(
    (o) => {
      o !== n.current && (t == null || t(o, n.current)), n.current = o;
    },
    //eslint-disable-next-line
    []
  );
  return [n, r];
}
function $e(e) {
  const t = E();
  return N(() => {
    t.current = e;
  }, [e]), t.current;
}
let nt = {};
function Je(e, t) {
  return R(() => {
    if (t)
      return t;
    const n = nt[e] == null ? 0 : nt[e] + 1;
    return nt[e] = n, e + "-" + n;
  }, [e, t]);
}
function qt(e) {
  return function(t) {
    for (var n = arguments.length, r = new Array(n > 1 ? n - 1 : 0), o = 1; o < n; o++)
      r[o - 1] = arguments[o];
    return r.reduce((i, s) => {
      const a = Object.entries(s);
      for (const [l, c] of a) {
        const d = i[l];
        d != null && (i[l] = d + e * c);
      }
      return i;
    }, {
      ...t
    });
  };
}
const he = /* @__PURE__ */ qt(1), Ke = /* @__PURE__ */ qt(-1);
function An(e) {
  return "clientX" in e && "clientY" in e;
}
function ht(e) {
  if (!e)
    return !1;
  const {
    KeyboardEvent: t
  } = B(e.target);
  return t && e instanceof t;
}
function Nn(e) {
  if (!e)
    return !1;
  const {
    TouchEvent: t
  } = B(e.target);
  return t && e instanceof t;
}
function We(e) {
  if (Nn(e)) {
    if (e.touches && e.touches.length) {
      const {
        clientX: t,
        clientY: n
      } = e.touches[0];
      return {
        x: t,
        y: n
      };
    } else if (e.changedTouches && e.changedTouches.length) {
      const {
        clientX: t,
        clientY: n
      } = e.changedTouches[0];
      return {
        x: t,
        y: n
      };
    }
  }
  return An(e) ? {
    x: e.clientX,
    y: e.clientY
  } : null;
}
const Oe = /* @__PURE__ */ Object.freeze({
  Translate: {
    toString(e) {
      if (!e)
        return;
      const {
        x: t,
        y: n
      } = e;
      return "translate3d(" + (t ? Math.round(t) : 0) + "px, " + (n ? Math.round(n) : 0) + "px, 0)";
    }
  },
  Scale: {
    toString(e) {
      if (!e)
        return;
      const {
        scaleX: t,
        scaleY: n
      } = e;
      return "scaleX(" + t + ") scaleY(" + n + ")";
    }
  },
  Transform: {
    toString(e) {
      if (e)
        return [Oe.Translate.toString(e), Oe.Scale.toString(e)].join(" ");
    }
  },
  Transition: {
    toString(e) {
      let {
        property: t,
        duration: n,
        easing: r
      } = e;
      return t + " " + n + "ms " + r;
    }
  }
}), Pt = "a,frame,iframe,input:not([type=hidden]):not(:disabled),select:not(:disabled),textarea:not(:disabled),button:not(:disabled),*[tabindex]";
function Tn(e) {
  return e.matches(Pt) ? e : e.querySelector(Pt);
}
const Mn = {
  display: "none"
};
function Ln(e) {
  let {
    id: t,
    value: n
  } = e;
  return A.createElement("div", {
    id: t,
    style: Mn
  }, n);
}
function In(e) {
  let {
    id: t,
    announcement: n,
    ariaLiveType: r = "assertive"
  } = e;
  const o = {
    position: "fixed",
    width: 1,
    height: 1,
    margin: -1,
    border: 0,
    padding: 0,
    overflow: "hidden",
    clip: "rect(0 0 0 0)",
    clipPath: "inset(100%)",
    whiteSpace: "nowrap"
  };
  return A.createElement("div", {
    id: t,
    style: o,
    role: "status",
    "aria-live": r,
    "aria-atomic": !0
  }, n);
}
function kn() {
  const [e, t] = $("");
  return {
    announce: P((r) => {
      r != null && t(r);
    }, []),
    announcement: e
  };
}
const Jt = /* @__PURE__ */ Ne(null);
function zn(e) {
  const t = ae(Jt);
  N(() => {
    if (!t)
      throw new Error("useDndMonitor must be used within a children of <DndContext>");
    return t(e);
  }, [e, t]);
}
function Pn() {
  const [e] = $(() => /* @__PURE__ */ new Set()), t = P((r) => (e.add(r), () => e.delete(r)), [e]);
  return [P((r) => {
    let {
      type: o,
      event: i
    } = r;
    e.forEach((s) => {
      var a;
      return (a = s[o]) == null ? void 0 : a.call(s, i);
    });
  }, [e]), t];
}
const Bn = {
  draggable: `
    To pick up a draggable item, press the space bar.
    While dragging, use the arrow keys to move the item.
    Press space again to drop the item in its new position, or press escape to cancel.
  `
}, Fn = {
  onDragStart(e) {
    let {
      active: t
    } = e;
    return "Picked up draggable item " + t.id + ".";
  },
  onDragOver(e) {
    let {
      active: t,
      over: n
    } = e;
    return n ? "Draggable item " + t.id + " was moved over droppable area " + n.id + "." : "Draggable item " + t.id + " is no longer over a droppable area.";
  },
  onDragEnd(e) {
    let {
      active: t,
      over: n
    } = e;
    return n ? "Draggable item " + t.id + " was dropped over droppable area " + n.id : "Draggable item " + t.id + " was dropped.";
  },
  onDragCancel(e) {
    let {
      active: t
    } = e;
    return "Dragging was cancelled. Draggable item " + t.id + " was dropped.";
  }
};
function jn(e) {
  let {
    announcements: t = Fn,
    container: n,
    hiddenTextDescribedById: r,
    screenReaderInstructions: o = Bn
  } = e;
  const {
    announce: i,
    announcement: s
  } = kn(), a = Je("DndLiveRegion"), [l, c] = $(!1);
  if (N(() => {
    c(!0);
  }, []), zn(R(() => ({
    onDragStart(u) {
      let {
        active: g
      } = u;
      i(t.onDragStart({
        active: g
      }));
    },
    onDragMove(u) {
      let {
        active: g,
        over: f
      } = u;
      t.onDragMove && i(t.onDragMove({
        active: g,
        over: f
      }));
    },
    onDragOver(u) {
      let {
        active: g,
        over: f
      } = u;
      i(t.onDragOver({
        active: g,
        over: f
      }));
    },
    onDragEnd(u) {
      let {
        active: g,
        over: f
      } = u;
      i(t.onDragEnd({
        active: g,
        over: f
      }));
    },
    onDragCancel(u) {
      let {
        active: g,
        over: f
      } = u;
      i(t.onDragCancel({
        active: g,
        over: f
      }));
    }
  }), [i, t])), !l)
    return null;
  const d = A.createElement(A.Fragment, null, A.createElement(Ln, {
    id: r,
    value: o.draggable
  }), A.createElement(In, {
    id: a,
    announcement: s
  }));
  return n ? En(d, n) : d;
}
var M;
(function(e) {
  e.DragStart = "dragStart", e.DragMove = "dragMove", e.DragEnd = "dragEnd", e.DragCancel = "dragCancel", e.DragOver = "dragOver", e.RegisterDroppable = "registerDroppable", e.SetDroppableDisabled = "setDroppableDisabled", e.UnregisterDroppable = "unregisterDroppable";
})(M || (M = {}));
function Ue() {
}
function to(e, t) {
  return R(
    () => ({
      sensor: e,
      options: t ?? {}
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [e, t]
  );
}
function no() {
  for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++)
    t[n] = arguments[n];
  return R(
    () => [...t].filter((r) => r != null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...t]
  );
}
const V = /* @__PURE__ */ Object.freeze({
  x: 0,
  y: 0
});
function Xn(e, t) {
  const n = We(e);
  if (!n)
    return "0 0";
  const r = {
    x: (n.x - t.left) / t.width * 100,
    y: (n.y - t.top) / t.height * 100
  };
  return r.x + "% " + r.y + "%";
}
function Yn(e, t) {
  let {
    data: {
      value: n
    }
  } = e, {
    data: {
      value: r
    }
  } = t;
  return r - n;
}
function $n(e, t) {
  if (!e || e.length === 0)
    return null;
  const [n] = e;
  return n[t];
}
function Kn(e, t) {
  const n = Math.max(t.top, e.top), r = Math.max(t.left, e.left), o = Math.min(t.left + t.width, e.left + e.width), i = Math.min(t.top + t.height, e.top + e.height), s = o - r, a = i - n;
  if (r < o && n < i) {
    const l = t.width * t.height, c = e.width * e.height, d = s * a, u = d / (l + c - d);
    return Number(u.toFixed(4));
  }
  return 0;
}
const Wn = (e) => {
  let {
    collisionRect: t,
    droppableRects: n,
    droppableContainers: r
  } = e;
  const o = [];
  for (const i of r) {
    const {
      id: s
    } = i, a = n.get(s);
    if (a) {
      const l = Kn(a, t);
      l > 0 && o.push({
        id: s,
        data: {
          droppableContainer: i,
          value: l
        }
      });
    }
  }
  return o.sort(Yn);
};
function Un(e, t, n) {
  return {
    ...e,
    scaleX: t && n ? t.width / n.width : 1,
    scaleY: t && n ? t.height / n.height : 1
  };
}
function Gt(e, t) {
  return e && t ? {
    x: e.left - t.left,
    y: e.top - t.top
  } : V;
}
function Hn(e) {
  return function(n) {
    for (var r = arguments.length, o = new Array(r > 1 ? r - 1 : 0), i = 1; i < r; i++)
      o[i - 1] = arguments[i];
    return o.reduce((s, a) => ({
      ...s,
      top: s.top + e * a.y,
      bottom: s.bottom + e * a.y,
      left: s.left + e * a.x,
      right: s.right + e * a.x
    }), {
      ...n
    });
  };
}
const Vn = /* @__PURE__ */ Hn(1);
function _t(e) {
  if (e.startsWith("matrix3d(")) {
    const t = e.slice(9, -1).split(/, /);
    return {
      x: +t[12],
      y: +t[13],
      scaleX: +t[0],
      scaleY: +t[5]
    };
  } else if (e.startsWith("matrix(")) {
    const t = e.slice(7, -1).split(/, /);
    return {
      x: +t[4],
      y: +t[5],
      scaleX: +t[0],
      scaleY: +t[3]
    };
  }
  return null;
}
function qn(e, t, n) {
  const r = _t(t);
  if (!r)
    return e;
  const {
    scaleX: o,
    scaleY: i,
    x: s,
    y: a
  } = r, l = e.left - s - (1 - o) * parseFloat(n), c = e.top - a - (1 - i) * parseFloat(n.slice(n.indexOf(" ") + 1)), d = o ? e.width / o : e.width, u = i ? e.height / i : e.height;
  return {
    width: d,
    height: u,
    top: c,
    right: l + d,
    bottom: c + u,
    left: l
  };
}
const Jn = {
  ignoreTransform: !1
};
function Le(e, t) {
  t === void 0 && (t = Jn);
  let n = e.getBoundingClientRect();
  if (t.ignoreTransform) {
    const {
      transform: c,
      transformOrigin: d
    } = B(e).getComputedStyle(e);
    c && (n = qn(n, c, d));
  }
  const {
    top: r,
    left: o,
    width: i,
    height: s,
    bottom: a,
    right: l
  } = n;
  return {
    top: r,
    left: o,
    width: i,
    height: s,
    bottom: a,
    right: l
  };
}
function Bt(e) {
  return Le(e, {
    ignoreTransform: !0
  });
}
function Gn(e) {
  const t = e.innerWidth, n = e.innerHeight;
  return {
    top: 0,
    left: 0,
    right: t,
    bottom: n,
    width: t,
    height: n
  };
}
function _n(e, t) {
  return t === void 0 && (t = B(e).getComputedStyle(e)), t.position === "fixed";
}
function Qn(e, t) {
  t === void 0 && (t = B(e).getComputedStyle(e));
  const n = /(auto|scroll|overlay)/;
  return ["overflow", "overflowX", "overflowY"].some((o) => {
    const i = t[o];
    return typeof i == "string" ? n.test(i) : !1;
  });
}
function gt(e, t) {
  const n = [];
  function r(o) {
    if (t != null && n.length >= t || !o)
      return n;
    if (ft(o) && o.scrollingElement != null && !n.includes(o.scrollingElement))
      return n.push(o.scrollingElement), n;
    if (!Te(o) || Vt(o) || n.includes(o))
      return n;
    const i = B(e).getComputedStyle(o);
    return o !== e && Qn(o, i) && n.push(o), _n(o, i) ? n : r(o.parentNode);
  }
  return e ? r(e) : n;
}
function Qt(e) {
  const [t] = gt(e, 1);
  return t ?? null;
}
function rt(e) {
  return !Ve || !e ? null : ge(e) ? e : dt(e) ? ft(e) || e === pe(e).scrollingElement ? window : Te(e) ? e : null : null;
}
function Zt(e) {
  return ge(e) ? e.scrollX : e.scrollLeft;
}
function en(e) {
  return ge(e) ? e.scrollY : e.scrollTop;
}
function at(e) {
  return {
    x: Zt(e),
    y: en(e)
  };
}
var I;
(function(e) {
  e[e.Forward = 1] = "Forward", e[e.Backward = -1] = "Backward";
})(I || (I = {}));
function tn(e) {
  return !Ve || !e ? !1 : e === document.scrollingElement;
}
function nn(e) {
  const t = {
    x: 0,
    y: 0
  }, n = tn(e) ? {
    height: window.innerHeight,
    width: window.innerWidth
  } : {
    height: e.clientHeight,
    width: e.clientWidth
  }, r = {
    x: e.scrollWidth - n.width,
    y: e.scrollHeight - n.height
  }, o = e.scrollTop <= t.y, i = e.scrollLeft <= t.x, s = e.scrollTop >= r.y, a = e.scrollLeft >= r.x;
  return {
    isTop: o,
    isLeft: i,
    isBottom: s,
    isRight: a,
    maxScroll: r,
    minScroll: t
  };
}
const Zn = {
  x: 0.2,
  y: 0.2
};
function er(e, t, n, r, o) {
  let {
    top: i,
    left: s,
    right: a,
    bottom: l
  } = n;
  r === void 0 && (r = 10), o === void 0 && (o = Zn);
  const {
    isTop: c,
    isBottom: d,
    isLeft: u,
    isRight: g
  } = nn(e), f = {
    x: 0,
    y: 0
  }, D = {
    x: 0,
    y: 0
  }, h = {
    height: t.height * o.y,
    width: t.width * o.x
  };
  return !c && i <= t.top + h.height ? (f.y = I.Backward, D.y = r * Math.abs((t.top + h.height - i) / h.height)) : !d && l >= t.bottom - h.height && (f.y = I.Forward, D.y = r * Math.abs((t.bottom - h.height - l) / h.height)), !g && a >= t.right - h.width ? (f.x = I.Forward, D.x = r * Math.abs((t.right - h.width - a) / h.width)) : !u && s <= t.left + h.width && (f.x = I.Backward, D.x = r * Math.abs((t.left + h.width - s) / h.width)), {
    direction: f,
    speed: D
  };
}
function tr(e) {
  if (e === document.scrollingElement) {
    const {
      innerWidth: i,
      innerHeight: s
    } = window;
    return {
      top: 0,
      left: 0,
      right: i,
      bottom: s,
      width: i,
      height: s
    };
  }
  const {
    top: t,
    left: n,
    right: r,
    bottom: o
  } = e.getBoundingClientRect();
  return {
    top: t,
    left: n,
    right: r,
    bottom: o,
    width: e.clientWidth,
    height: e.clientHeight
  };
}
function rn(e) {
  return e.reduce((t, n) => he(t, at(n)), V);
}
function nr(e) {
  return e.reduce((t, n) => t + Zt(n), 0);
}
function rr(e) {
  return e.reduce((t, n) => t + en(n), 0);
}
function on(e, t) {
  if (t === void 0 && (t = Le), !e)
    return;
  const {
    top: n,
    left: r,
    bottom: o,
    right: i
  } = t(e);
  Qt(e) && (o <= 0 || i <= 0 || n >= window.innerHeight || r >= window.innerWidth) && e.scrollIntoView({
    block: "center",
    inline: "center"
  });
}
const or = [["x", ["left", "right"], nr], ["y", ["top", "bottom"], rr]];
class pt {
  constructor(t, n) {
    this.rect = void 0, this.width = void 0, this.height = void 0, this.top = void 0, this.bottom = void 0, this.right = void 0, this.left = void 0;
    const r = gt(n), o = rn(r);
    this.rect = {
      ...t
    }, this.width = t.width, this.height = t.height;
    for (const [i, s, a] of or)
      for (const l of s)
        Object.defineProperty(this, l, {
          get: () => {
            const c = a(r), d = o[i] - c;
            return this.rect[l] + d;
          },
          enumerable: !0
        });
    Object.defineProperty(this, "rect", {
      enumerable: !1
    });
  }
}
class Se {
  constructor(t) {
    this.target = void 0, this.listeners = [], this.removeAll = () => {
      this.listeners.forEach((n) => {
        var r;
        return (r = this.target) == null ? void 0 : r.removeEventListener(...n);
      });
    }, this.target = t;
  }
  add(t, n, r) {
    var o;
    (o = this.target) == null || o.addEventListener(t, n, r), this.listeners.push([t, n, r]);
  }
}
function ir(e) {
  const {
    EventTarget: t
  } = B(e);
  return e instanceof t ? e : pe(e);
}
function ot(e, t) {
  const n = Math.abs(e.x), r = Math.abs(e.y);
  return typeof t == "number" ? Math.sqrt(n ** 2 + r ** 2) > t : "x" in t && "y" in t ? n > t.x && r > t.y : "x" in t ? n > t.x : "y" in t ? r > t.y : !1;
}
var Y;
(function(e) {
  e.Click = "click", e.DragStart = "dragstart", e.Keydown = "keydown", e.ContextMenu = "contextmenu", e.Resize = "resize", e.SelectionChange = "selectionchange", e.VisibilityChange = "visibilitychange";
})(Y || (Y = {}));
function Ft(e) {
  e.preventDefault();
}
function sr(e) {
  e.stopPropagation();
}
var x;
(function(e) {
  e.Space = "Space", e.Down = "ArrowDown", e.Right = "ArrowRight", e.Left = "ArrowLeft", e.Up = "ArrowUp", e.Esc = "Escape", e.Enter = "Enter";
})(x || (x = {}));
const sn = {
  start: [x.Space, x.Enter],
  cancel: [x.Esc],
  end: [x.Space, x.Enter]
}, ar = (e, t) => {
  let {
    currentCoordinates: n
  } = t;
  switch (e.code) {
    case x.Right:
      return {
        ...n,
        x: n.x + 25
      };
    case x.Left:
      return {
        ...n,
        x: n.x - 25
      };
    case x.Down:
      return {
        ...n,
        y: n.y + 25
      };
    case x.Up:
      return {
        ...n,
        y: n.y - 25
      };
  }
};
class an {
  constructor(t) {
    this.props = void 0, this.autoScrollEnabled = !1, this.referenceCoordinates = void 0, this.listeners = void 0, this.windowListeners = void 0, this.props = t;
    const {
      event: {
        target: n
      }
    } = t;
    this.props = t, this.listeners = new Se(pe(n)), this.windowListeners = new Se(B(n)), this.handleKeyDown = this.handleKeyDown.bind(this), this.handleCancel = this.handleCancel.bind(this), this.attach();
  }
  attach() {
    this.handleStart(), this.windowListeners.add(Y.Resize, this.handleCancel), this.windowListeners.add(Y.VisibilityChange, this.handleCancel), setTimeout(() => this.listeners.add(Y.Keydown, this.handleKeyDown));
  }
  handleStart() {
    const {
      activeNode: t,
      onStart: n
    } = this.props, r = t.node.current;
    r && on(r), n(V);
  }
  handleKeyDown(t) {
    if (ht(t)) {
      const {
        active: n,
        context: r,
        options: o
      } = this.props, {
        keyboardCodes: i = sn,
        coordinateGetter: s = ar,
        scrollBehavior: a = "smooth"
      } = o, {
        code: l
      } = t;
      if (i.end.includes(l)) {
        this.handleEnd(t);
        return;
      }
      if (i.cancel.includes(l)) {
        this.handleCancel(t);
        return;
      }
      const {
        collisionRect: c
      } = r.current, d = c ? {
        x: c.left,
        y: c.top
      } : V;
      this.referenceCoordinates || (this.referenceCoordinates = d);
      const u = s(t, {
        active: n,
        context: r.current,
        currentCoordinates: d
      });
      if (u) {
        const g = Ke(u, d), f = {
          x: 0,
          y: 0
        }, {
          scrollableAncestors: D
        } = r.current;
        for (const h of D) {
          const p = t.code, {
            isTop: b,
            isRight: w,
            isLeft: y,
            isBottom: L,
            maxScroll: S,
            minScroll: C
          } = nn(h), v = tr(h), m = {
            x: Math.min(p === x.Right ? v.right - v.width / 2 : v.right, Math.max(p === x.Right ? v.left : v.left + v.width / 2, u.x)),
            y: Math.min(p === x.Down ? v.bottom - v.height / 2 : v.bottom, Math.max(p === x.Down ? v.top : v.top + v.height / 2, u.y))
          }, O = p === x.Right && !w || p === x.Left && !y, k = p === x.Down && !L || p === x.Up && !b;
          if (O && m.x !== u.x) {
            const T = h.scrollLeft + g.x, G = p === x.Right && T <= S.x || p === x.Left && T >= C.x;
            if (G && !g.y) {
              h.scrollTo({
                left: T,
                behavior: a
              });
              return;
            }
            G ? f.x = h.scrollLeft - T : f.x = p === x.Right ? h.scrollLeft - S.x : h.scrollLeft - C.x, f.x && h.scrollBy({
              left: -f.x,
              behavior: a
            });
            break;
          } else if (k && m.y !== u.y) {
            const T = h.scrollTop + g.y, G = p === x.Down && T <= S.y || p === x.Up && T >= C.y;
            if (G && !g.x) {
              h.scrollTo({
                top: T,
                behavior: a
              });
              return;
            }
            G ? f.y = h.scrollTop - T : f.y = p === x.Down ? h.scrollTop - S.y : h.scrollTop - C.y, f.y && h.scrollBy({
              top: -f.y,
              behavior: a
            });
            break;
          }
        }
        this.handleMove(t, he(Ke(u, this.referenceCoordinates), f));
      }
    }
  }
  handleMove(t, n) {
    const {
      onMove: r
    } = this.props;
    t.preventDefault(), r(n);
  }
  handleEnd(t) {
    const {
      onEnd: n
    } = this.props;
    t.preventDefault(), this.detach(), n();
  }
  handleCancel(t) {
    const {
      onCancel: n
    } = this.props;
    t.preventDefault(), this.detach(), n();
  }
  detach() {
    this.listeners.removeAll(), this.windowListeners.removeAll();
  }
}
an.activators = [{
  eventName: "onKeyDown",
  handler: (e, t, n) => {
    let {
      keyboardCodes: r = sn,
      onActivation: o
    } = t, {
      active: i
    } = n;
    const {
      code: s
    } = e.nativeEvent;
    if (r.start.includes(s)) {
      const a = i.activatorNode.current;
      return a && e.target !== a ? !1 : (e.preventDefault(), o == null || o({
        event: e.nativeEvent
      }), !0);
    }
    return !1;
  }
}];
function jt(e) {
  return !!(e && "distance" in e);
}
function Xt(e) {
  return !!(e && "delay" in e);
}
class vt {
  constructor(t, n, r) {
    var o;
    r === void 0 && (r = ir(t.event.target)), this.props = void 0, this.events = void 0, this.autoScrollEnabled = !0, this.document = void 0, this.activated = !1, this.initialCoordinates = void 0, this.timeoutId = null, this.listeners = void 0, this.documentListeners = void 0, this.windowListeners = void 0, this.props = t, this.events = n;
    const {
      event: i
    } = t, {
      target: s
    } = i;
    this.props = t, this.events = n, this.document = pe(s), this.documentListeners = new Se(this.document), this.listeners = new Se(r), this.windowListeners = new Se(B(s)), this.initialCoordinates = (o = We(i)) != null ? o : V, this.handleStart = this.handleStart.bind(this), this.handleMove = this.handleMove.bind(this), this.handleEnd = this.handleEnd.bind(this), this.handleCancel = this.handleCancel.bind(this), this.handleKeydown = this.handleKeydown.bind(this), this.removeTextSelection = this.removeTextSelection.bind(this), this.attach();
  }
  attach() {
    const {
      events: t,
      props: {
        options: {
          activationConstraint: n,
          bypassActivationConstraint: r
        }
      }
    } = this;
    if (this.listeners.add(t.move.name, this.handleMove, {
      passive: !1
    }), this.listeners.add(t.end.name, this.handleEnd), this.windowListeners.add(Y.Resize, this.handleCancel), this.windowListeners.add(Y.DragStart, Ft), this.windowListeners.add(Y.VisibilityChange, this.handleCancel), this.windowListeners.add(Y.ContextMenu, Ft), this.documentListeners.add(Y.Keydown, this.handleKeydown), n) {
      if (r != null && r({
        event: this.props.event,
        activeNode: this.props.activeNode,
        options: this.props.options
      }))
        return this.handleStart();
      if (Xt(n)) {
        this.timeoutId = setTimeout(this.handleStart, n.delay);
        return;
      }
      if (jt(n))
        return;
    }
    this.handleStart();
  }
  detach() {
    this.listeners.removeAll(), this.windowListeners.removeAll(), setTimeout(this.documentListeners.removeAll, 50), this.timeoutId !== null && (clearTimeout(this.timeoutId), this.timeoutId = null);
  }
  handleStart() {
    const {
      initialCoordinates: t
    } = this, {
      onStart: n
    } = this.props;
    t && (this.activated = !0, this.documentListeners.add(Y.Click, sr, {
      capture: !0
    }), this.removeTextSelection(), this.documentListeners.add(Y.SelectionChange, this.removeTextSelection), n(t));
  }
  handleMove(t) {
    var n;
    const {
      activated: r,
      initialCoordinates: o,
      props: i
    } = this, {
      onMove: s,
      options: {
        activationConstraint: a
      }
    } = i;
    if (!o)
      return;
    const l = (n = We(t)) != null ? n : V, c = Ke(o, l);
    if (!r && a) {
      if (jt(a)) {
        if (a.tolerance != null && ot(c, a.tolerance))
          return this.handleCancel();
        if (ot(c, a.distance))
          return this.handleStart();
      }
      return Xt(a) && ot(c, a.tolerance) ? this.handleCancel() : void 0;
    }
    t.cancelable && t.preventDefault(), s(l);
  }
  handleEnd() {
    const {
      onEnd: t
    } = this.props;
    this.detach(), t();
  }
  handleCancel() {
    const {
      onCancel: t
    } = this.props;
    this.detach(), t();
  }
  handleKeydown(t) {
    t.code === x.Esc && this.handleCancel();
  }
  removeTextSelection() {
    var t;
    (t = this.document.getSelection()) == null || t.removeAllRanges();
  }
}
const lr = {
  move: {
    name: "pointermove"
  },
  end: {
    name: "pointerup"
  }
};
class ln extends vt {
  constructor(t) {
    const {
      event: n
    } = t, r = pe(n.target);
    super(t, lr, r);
  }
}
ln.activators = [{
  eventName: "onPointerDown",
  handler: (e, t) => {
    let {
      nativeEvent: n
    } = e, {
      onActivation: r
    } = t;
    return !n.isPrimary || n.button !== 0 ? !1 : (r == null || r({
      event: n
    }), !0);
  }
}];
const cr = {
  move: {
    name: "mousemove"
  },
  end: {
    name: "mouseup"
  }
};
var lt;
(function(e) {
  e[e.RightClick = 2] = "RightClick";
})(lt || (lt = {}));
class ur extends vt {
  constructor(t) {
    super(t, cr, pe(t.event.target));
  }
}
ur.activators = [{
  eventName: "onMouseDown",
  handler: (e, t) => {
    let {
      nativeEvent: n
    } = e, {
      onActivation: r
    } = t;
    return n.button === lt.RightClick ? !1 : (r == null || r({
      event: n
    }), !0);
  }
}];
const it = {
  move: {
    name: "touchmove"
  },
  end: {
    name: "touchend"
  }
};
class dr extends vt {
  constructor(t) {
    super(t, it);
  }
  static setup() {
    return window.addEventListener(it.move.name, t, {
      capture: !1,
      passive: !1
    }), function() {
      window.removeEventListener(it.move.name, t);
    };
    function t() {
    }
  }
}
dr.activators = [{
  eventName: "onTouchStart",
  handler: (e, t) => {
    let {
      nativeEvent: n
    } = e, {
      onActivation: r
    } = t;
    const {
      touches: o
    } = n;
    return o.length > 1 ? !1 : (r == null || r({
      event: n
    }), !0);
  }
}];
var Ce;
(function(e) {
  e[e.Pointer = 0] = "Pointer", e[e.DraggableRect = 1] = "DraggableRect";
})(Ce || (Ce = {}));
var He;
(function(e) {
  e[e.TreeOrder = 0] = "TreeOrder", e[e.ReversedTreeOrder = 1] = "ReversedTreeOrder";
})(He || (He = {}));
function fr(e) {
  let {
    acceleration: t,
    activator: n = Ce.Pointer,
    canScroll: r,
    draggingRect: o,
    enabled: i,
    interval: s = 5,
    order: a = He.TreeOrder,
    pointerCoordinates: l,
    scrollableAncestors: c,
    scrollableAncestorRects: d,
    delta: u,
    threshold: g
  } = e;
  const f = gr({
    delta: u,
    disabled: !i
  }), [D, h] = On(), p = E({
    x: 0,
    y: 0
  }), b = E({
    x: 0,
    y: 0
  }), w = R(() => {
    switch (n) {
      case Ce.Pointer:
        return l ? {
          top: l.y,
          bottom: l.y,
          left: l.x,
          right: l.x
        } : null;
      case Ce.DraggableRect:
        return o;
    }
  }, [n, o, l]), y = E(null), L = P(() => {
    const C = y.current;
    if (!C)
      return;
    const v = p.current.x * b.current.x, m = p.current.y * b.current.y;
    C.scrollBy(v, m);
  }, []), S = R(() => a === He.TreeOrder ? [...c].reverse() : c, [a, c]);
  N(
    () => {
      if (!i || !c.length || !w) {
        h();
        return;
      }
      for (const C of S) {
        if ((r == null ? void 0 : r(C)) === !1)
          continue;
        const v = c.indexOf(C), m = d[v];
        if (!m)
          continue;
        const {
          direction: O,
          speed: k
        } = er(C, m, w, t, g);
        for (const T of ["x", "y"])
          f[T][O[T]] || (k[T] = 0, O[T] = 0);
        if (k.x > 0 || k.y > 0) {
          h(), y.current = C, D(L, s), p.current = k, b.current = O;
          return;
        }
      }
      p.current = {
        x: 0,
        y: 0
      }, b.current = {
        x: 0,
        y: 0
      }, h();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      L,
      r,
      h,
      i,
      s,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(w),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(f),
      D,
      c,
      S,
      d,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(g)
    ]
  );
}
const hr = {
  x: {
    [I.Backward]: !1,
    [I.Forward]: !1
  },
  y: {
    [I.Backward]: !1,
    [I.Forward]: !1
  }
};
function gr(e) {
  let {
    delta: t,
    disabled: n
  } = e;
  const r = $e(t);
  return Me((o) => {
    if (n || !r || !o)
      return hr;
    const i = {
      x: Math.sign(t.x - r.x),
      y: Math.sign(t.y - r.y)
    };
    return {
      x: {
        [I.Backward]: o.x[I.Backward] || i.x === -1,
        [I.Forward]: o.x[I.Forward] || i.x === 1
      },
      y: {
        [I.Backward]: o.y[I.Backward] || i.y === -1,
        [I.Forward]: o.y[I.Forward] || i.y === 1
      }
    };
  }, [n, t, r]);
}
function pr(e, t) {
  const n = t !== null ? e.get(t) : void 0, r = n ? n.node.current : null;
  return Me((o) => {
    var i;
    return t === null ? null : (i = r ?? o) != null ? i : null;
  }, [r, t]);
}
function vr(e, t) {
  return R(() => e.reduce((n, r) => {
    const {
      sensor: o
    } = r, i = o.activators.map((s) => ({
      eventName: s.eventName,
      handler: t(s.handler, r)
    }));
    return [...n, ...i];
  }, []), [e, t]);
}
var Ae;
(function(e) {
  e[e.Always = 0] = "Always", e[e.BeforeDragging = 1] = "BeforeDragging", e[e.WhileDragging = 2] = "WhileDragging";
})(Ae || (Ae = {}));
var ct;
(function(e) {
  e.Optimized = "optimized";
})(ct || (ct = {}));
const Yt = /* @__PURE__ */ new Map();
function br(e, t) {
  let {
    dragging: n,
    dependencies: r,
    config: o
  } = t;
  const [i, s] = $(null), {
    frequency: a,
    measure: l,
    strategy: c
  } = o, d = E(e), u = p(), g = Ee(u), f = P(function(b) {
    b === void 0 && (b = []), !g.current && s((w) => w === null ? b : w.concat(b.filter((y) => !w.includes(y))));
  }, [g]), D = E(null), h = Me((b) => {
    if (u && !n)
      return Yt;
    if (!b || b === Yt || d.current !== e || i != null) {
      const w = /* @__PURE__ */ new Map();
      for (let y of e) {
        if (!y)
          continue;
        if (i && i.length > 0 && !i.includes(y.id) && y.rect.current) {
          w.set(y.id, y.rect.current);
          continue;
        }
        const L = y.node.current, S = L ? new pt(l(L), L) : null;
        y.rect.current = S, S && w.set(y.id, S);
      }
      return w;
    }
    return b;
  }, [e, i, n, u, l]);
  return N(() => {
    d.current = e;
  }, [e]), N(
    () => {
      u || f();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [n, u]
  ), N(
    () => {
      i && i.length > 0 && s(null);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(i)]
  ), N(
    () => {
      u || typeof a != "number" || D.current !== null || (D.current = setTimeout(() => {
        f(), D.current = null;
      }, a));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [a, u, f, ...r]
  ), {
    droppableRects: h,
    measureDroppableContainers: f,
    measuringScheduled: i != null
  };
  function p() {
    switch (c) {
      case Ae.Always:
        return !1;
      case Ae.BeforeDragging:
        return n;
      default:
        return !n;
    }
  }
}
function bt(e, t) {
  return Me((n) => e ? n || (typeof t == "function" ? t(e) : e) : null, [t, e]);
}
function yr(e, t) {
  return bt(e, t);
}
function mr(e) {
  let {
    callback: t,
    disabled: n
  } = e;
  const r = qe(t), o = R(() => {
    if (n || typeof window > "u" || typeof window.MutationObserver > "u")
      return;
    const {
      MutationObserver: i
    } = window;
    return new i(r);
  }, [r, n]);
  return N(() => () => o == null ? void 0 : o.disconnect(), [o]), o;
}
function Ge(e) {
  let {
    callback: t,
    disabled: n
  } = e;
  const r = qe(t), o = R(
    () => {
      if (n || typeof window > "u" || typeof window.ResizeObserver > "u")
        return;
      const {
        ResizeObserver: i
      } = window;
      return new i(r);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [n]
  );
  return N(() => () => o == null ? void 0 : o.disconnect(), [o]), o;
}
function wr(e) {
  return new pt(Le(e), e);
}
function $t(e, t, n) {
  t === void 0 && (t = wr);
  const [r, o] = ut(a, null), i = mr({
    callback(l) {
      if (e)
        for (const c of l) {
          const {
            type: d,
            target: u
          } = c;
          if (d === "childList" && u instanceof HTMLElement && u.contains(e)) {
            o();
            break;
          }
        }
    }
  }), s = Ge({
    callback: o
  });
  return J(() => {
    o(), e ? (s == null || s.observe(e), i == null || i.observe(document.body, {
      childList: !0,
      subtree: !0
    })) : (s == null || s.disconnect(), i == null || i.disconnect());
  }, [e]), r;
  function a(l) {
    if (!e)
      return null;
    if (e.isConnected === !1) {
      var c;
      return (c = l ?? n) != null ? c : null;
    }
    const d = t(e);
    return JSON.stringify(l) === JSON.stringify(d) ? l : d;
  }
}
function Dr(e) {
  const t = bt(e);
  return Gt(e, t);
}
const Kt = [];
function xr(e) {
  const t = E(e), n = Me((r) => e ? r && r !== Kt && e && t.current && e.parentNode === t.current.parentNode ? r : gt(e) : Kt, [e]);
  return N(() => {
    t.current = e;
  }, [e]), n;
}
function Sr(e) {
  const [t, n] = $(null), r = E(e), o = P((i) => {
    const s = rt(i.target);
    s && n((a) => a ? (a.set(s, at(s)), new Map(a)) : null);
  }, []);
  return N(() => {
    const i = r.current;
    if (e !== i) {
      s(i);
      const a = e.map((l) => {
        const c = rt(l);
        return c ? (c.addEventListener("scroll", o, {
          passive: !0
        }), [c, at(c)]) : null;
      }).filter((l) => l != null);
      n(a.length ? new Map(a) : null), r.current = e;
    }
    return () => {
      s(e), s(i);
    };
    function s(a) {
      a.forEach((l) => {
        const c = rt(l);
        c == null || c.removeEventListener("scroll", o);
      });
    }
  }, [o, e]), R(() => e.length ? t ? Array.from(t.values()).reduce((i, s) => he(i, s), V) : rn(e) : V, [e, t]);
}
function Wt(e, t) {
  t === void 0 && (t = []);
  const n = E(null);
  return N(
    () => {
      n.current = null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    t
  ), N(() => {
    const r = e !== V;
    r && !n.current && (n.current = e), !r && n.current && (n.current = null);
  }, [e]), n.current ? Ke(e, n.current) : V;
}
function Cr(e) {
  N(
    () => {
      if (!Ve)
        return;
      const t = e.map((n) => {
        let {
          sensor: r
        } = n;
        return r.setup == null ? void 0 : r.setup();
      });
      return () => {
        for (const n of t)
          n == null || n();
      };
    },
    // TO-DO: Sensors length could theoretically change which would not be a valid dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    e.map((t) => {
      let {
        sensor: n
      } = t;
      return n;
    })
  );
}
function Rr(e, t) {
  return R(() => e.reduce((n, r) => {
    let {
      eventName: o,
      handler: i
    } = r;
    return n[o] = (s) => {
      i(s, t);
    }, n;
  }, {}), [e, t]);
}
function cn(e) {
  return R(() => e ? Gn(e) : null, [e]);
}
const st = [];
function Er(e, t) {
  t === void 0 && (t = Le);
  const [n] = e, r = cn(n ? B(n) : null), [o, i] = ut(a, st), s = Ge({
    callback: i
  });
  return e.length > 0 && o === st && i(), J(() => {
    e.length ? e.forEach((l) => s == null ? void 0 : s.observe(l)) : (s == null || s.disconnect(), i());
  }, [e]), o;
  function a() {
    return e.length ? e.map((l) => tn(l) ? r : new pt(t(l), l)) : st;
  }
}
function un(e) {
  if (!e)
    return null;
  if (e.children.length > 1)
    return e;
  const t = e.children[0];
  return Te(t) ? t : e;
}
function Or(e) {
  let {
    measure: t
  } = e;
  const [n, r] = $(null), o = P((c) => {
    for (const {
      target: d
    } of c)
      if (Te(d)) {
        r((u) => {
          const g = t(d);
          return u ? {
            ...u,
            width: g.width,
            height: g.height
          } : g;
        });
        break;
      }
  }, [t]), i = Ge({
    callback: o
  }), s = P((c) => {
    const d = un(c);
    i == null || i.disconnect(), d && (i == null || i.observe(d)), r(d ? t(d) : null);
  }, [t, i]), [a, l] = Ye(s);
  return R(() => ({
    nodeRef: a,
    rect: n,
    setRef: l
  }), [n, a, l]);
}
const Ar = [{
  sensor: ln,
  options: {}
}, {
  sensor: an,
  options: {}
}], Nr = {
  current: {}
}, Xe = {
  draggable: {
    measure: Bt
  },
  droppable: {
    measure: Bt,
    strategy: Ae.WhileDragging,
    frequency: ct.Optimized
  },
  dragOverlay: {
    measure: Le
  }
};
class Re extends Map {
  get(t) {
    var n;
    return t != null && (n = super.get(t)) != null ? n : void 0;
  }
  toArray() {
    return Array.from(this.values());
  }
  getEnabled() {
    return this.toArray().filter((t) => {
      let {
        disabled: n
      } = t;
      return !n;
    });
  }
  getNodeFor(t) {
    var n, r;
    return (n = (r = this.get(t)) == null ? void 0 : r.node.current) != null ? n : void 0;
  }
}
const Tr = {
  activatorEvent: null,
  active: null,
  activeNode: null,
  activeNodeRect: null,
  collisions: null,
  containerNodeRect: null,
  draggableNodes: /* @__PURE__ */ new Map(),
  droppableRects: /* @__PURE__ */ new Map(),
  droppableContainers: /* @__PURE__ */ new Re(),
  over: null,
  dragOverlay: {
    nodeRef: {
      current: null
    },
    rect: null,
    setRef: Ue
  },
  scrollableAncestors: [],
  scrollableAncestorRects: [],
  measuringConfiguration: Xe,
  measureDroppableContainers: Ue,
  windowRect: null,
  measuringScheduled: !1
}, dn = {
  activatorEvent: null,
  activators: [],
  active: null,
  activeNodeRect: null,
  ariaDescribedById: {
    draggable: ""
  },
  dispatch: Ue,
  draggableNodes: /* @__PURE__ */ new Map(),
  over: null,
  measureDroppableContainers: Ue
}, Ie = /* @__PURE__ */ Ne(dn), fn = /* @__PURE__ */ Ne(Tr);
function Mr() {
  return {
    draggable: {
      active: null,
      initialCoordinates: {
        x: 0,
        y: 0
      },
      nodes: /* @__PURE__ */ new Map(),
      translate: {
        x: 0,
        y: 0
      }
    },
    droppable: {
      containers: new Re()
    }
  };
}
function Lr(e, t) {
  switch (t.type) {
    case M.DragStart:
      return {
        ...e,
        draggable: {
          ...e.draggable,
          initialCoordinates: t.initialCoordinates,
          active: t.active
        }
      };
    case M.DragMove:
      return e.draggable.active ? {
        ...e,
        draggable: {
          ...e.draggable,
          translate: {
            x: t.coordinates.x - e.draggable.initialCoordinates.x,
            y: t.coordinates.y - e.draggable.initialCoordinates.y
          }
        }
      } : e;
    case M.DragEnd:
    case M.DragCancel:
      return {
        ...e,
        draggable: {
          ...e.draggable,
          active: null,
          initialCoordinates: {
            x: 0,
            y: 0
          },
          translate: {
            x: 0,
            y: 0
          }
        }
      };
    case M.RegisterDroppable: {
      const {
        element: n
      } = t, {
        id: r
      } = n, o = new Re(e.droppable.containers);
      return o.set(r, n), {
        ...e,
        droppable: {
          ...e.droppable,
          containers: o
        }
      };
    }
    case M.SetDroppableDisabled: {
      const {
        id: n,
        key: r,
        disabled: o
      } = t, i = e.droppable.containers.get(n);
      if (!i || r !== i.key)
        return e;
      const s = new Re(e.droppable.containers);
      return s.set(n, {
        ...i,
        disabled: o
      }), {
        ...e,
        droppable: {
          ...e.droppable,
          containers: s
        }
      };
    }
    case M.UnregisterDroppable: {
      const {
        id: n,
        key: r
      } = t, o = e.droppable.containers.get(n);
      if (!o || r !== o.key)
        return e;
      const i = new Re(e.droppable.containers);
      return i.delete(n), {
        ...e,
        droppable: {
          ...e.droppable,
          containers: i
        }
      };
    }
    default:
      return e;
  }
}
function Ir(e) {
  let {
    disabled: t
  } = e;
  const {
    active: n,
    activatorEvent: r,
    draggableNodes: o
  } = ae(Ie), i = $e(r), s = $e(n == null ? void 0 : n.id);
  return N(() => {
    if (!t && !r && i && s != null) {
      if (!ht(i) || document.activeElement === i.target)
        return;
      const a = o.get(s);
      if (!a)
        return;
      const {
        activatorNode: l,
        node: c
      } = a;
      if (!l.current && !c.current)
        return;
      requestAnimationFrame(() => {
        for (const d of [l.current, c.current]) {
          if (!d)
            continue;
          const u = Tn(d);
          if (u) {
            u.focus();
            break;
          }
        }
      });
    }
  }, [r, t, o, s, i]), null;
}
function hn(e, t) {
  let {
    transform: n,
    ...r
  } = t;
  return e != null && e.length ? e.reduce((o, i) => i({
    transform: o,
    ...r
  }), n) : n;
}
function kr(e) {
  return R(
    () => ({
      draggable: {
        ...Xe.draggable,
        ...e == null ? void 0 : e.draggable
      },
      droppable: {
        ...Xe.droppable,
        ...e == null ? void 0 : e.droppable
      },
      dragOverlay: {
        ...Xe.dragOverlay,
        ...e == null ? void 0 : e.dragOverlay
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [e == null ? void 0 : e.draggable, e == null ? void 0 : e.droppable, e == null ? void 0 : e.dragOverlay]
  );
}
function zr(e) {
  let {
    activeNode: t,
    measure: n,
    initialRect: r,
    config: o = !0
  } = e;
  const i = E(!1), {
    x: s,
    y: a
  } = typeof o == "boolean" ? {
    x: o,
    y: o
  } : o;
  J(() => {
    if (!s && !a || !t) {
      i.current = !1;
      return;
    }
    if (i.current || !r)
      return;
    const c = t == null ? void 0 : t.node.current;
    if (!c || c.isConnected === !1)
      return;
    const d = n(c), u = Gt(d, r);
    if (s || (u.x = 0), a || (u.y = 0), i.current = !0, Math.abs(u.x) > 0 || Math.abs(u.y) > 0) {
      const g = Qt(c);
      g && g.scrollBy({
        top: u.y,
        left: u.x
      });
    }
  }, [t, s, a, r, n]);
}
const _e = /* @__PURE__ */ Ne({
  ...V,
  scaleX: 1,
  scaleY: 1
});
var re;
(function(e) {
  e[e.Uninitialized = 0] = "Uninitialized", e[e.Initializing = 1] = "Initializing", e[e.Initialized = 2] = "Initialized";
})(re || (re = {}));
const ro = /* @__PURE__ */ Sn(function(t) {
  var n, r, o, i;
  let {
    id: s,
    accessibility: a,
    autoScroll: l = !0,
    children: c,
    sensors: d = Ar,
    collisionDetection: u = Wn,
    measuring: g,
    modifiers: f,
    ...D
  } = t;
  const h = ut(Lr, void 0, Mr), [p, b] = h, [w, y] = Pn(), [L, S] = $(re.Uninitialized), C = L === re.Initialized, {
    draggable: {
      active: v,
      nodes: m,
      translate: O
    },
    droppable: {
      containers: k
    }
  } = p, T = v ? m.get(v) : null, G = E({
    initial: null,
    translated: null
  }), _ = R(() => {
    var z;
    return v != null ? {
      id: v,
      // It's possible for the active node to unmount while dragging
      data: (z = T == null ? void 0 : T.data) != null ? z : Nr,
      rect: G
    } : null;
  }, [v, T]), Z = E(null), [yt, mt] = $(null), [Q, wt] = $(null), ve = Ee(D, Object.values(D)), Qe = Je("DndDescribedBy", s), Dt = R(() => k.getEnabled(), [k]), oe = kr(g), {
    droppableRects: le,
    measureDroppableContainers: ke,
    measuringScheduled: xt
  } = br(Dt, {
    dragging: C,
    dependencies: [O.x, O.y],
    config: oe.droppable
  }), K = pr(m, v), St = R(() => Q ? We(Q) : null, [Q]), Ct = Dn(), Rt = yr(K, oe.draggable.measure);
  zr({
    activeNode: v ? m.get(v) : null,
    config: Ct.layoutShiftCompensation,
    initialRect: Rt,
    measure: oe.draggable.measure
  });
  const W = $t(K, oe.draggable.measure, Rt), Ze = $t(K ? K.parentElement : null), ie = E({
    activatorEvent: null,
    active: null,
    activeNode: K,
    collisionRect: null,
    collisions: null,
    droppableRects: le,
    draggableNodes: m,
    draggingNode: null,
    draggingNodeRect: null,
    droppableContainers: k,
    over: null,
    scrollableAncestors: [],
    scrollAdjustedTranslate: null
  }), Et = k.getNodeFor((n = ie.current.over) == null ? void 0 : n.id), se = Or({
    measure: oe.dragOverlay.measure
  }), ze = (r = se.nodeRef.current) != null ? r : K, ce = C ? (o = se.rect) != null ? o : W : null, Ot = !!(se.nodeRef.current && se.rect), At = Dr(Ot ? null : W), et = cn(ze ? B(ze) : null), ee = xr(C ? Et ?? K : null), Pe = Er(ee), Be = hn(f, {
    transform: {
      x: O.x - At.x,
      y: O.y - At.y,
      scaleX: 1,
      scaleY: 1
    },
    activatorEvent: Q,
    active: _,
    activeNodeRect: W,
    containerNodeRect: Ze,
    draggingNodeRect: ce,
    over: ie.current.over,
    overlayNodeRect: se.rect,
    scrollableAncestors: ee,
    scrollableAncestorRects: Pe,
    windowRect: et
  }), Nt = St ? he(St, O) : null, Tt = Sr(ee), gn = Wt(Tt), pn = Wt(Tt, [W]), ue = he(Be, gn), de = ce ? Vn(ce, Be) : null, be = _ && de ? u({
    active: _,
    collisionRect: de,
    droppableRects: le,
    droppableContainers: Dt,
    pointerCoordinates: Nt
  }) : null, Mt = $n(be, "id"), [te, Lt] = $(null), vn = Ot ? Be : he(Be, pn), bn = Un(vn, (i = te == null ? void 0 : te.rect) != null ? i : null, W), It = P(
    (z, F) => {
      let {
        sensor: j,
        options: ne
      } = F;
      if (Z.current == null)
        return;
      const X = m.get(Z.current);
      if (!X)
        return;
      const U = z.nativeEvent, q = new j({
        active: Z.current,
        activeNode: X,
        event: U,
        options: ne,
        // Sensors need to be instantiated with refs for arguments that change over time
        // otherwise they are frozen in time with the stale arguments
        context: ie,
        onStart(H) {
          const ye = Z.current;
          if (ye == null)
            return;
          const me = m.get(ye);
          if (!me)
            return;
          const {
            onDragStart: Fe
          } = ve.current, je = {
            active: {
              id: ye,
              data: me.data,
              rect: G
            }
          };
          xe(() => {
            Fe == null || Fe(je), S(re.Initializing), b({
              type: M.DragStart,
              initialCoordinates: H,
              active: ye
            }), w({
              type: "onDragStart",
              event: je
            });
          });
        },
        onMove(H) {
          b({
            type: M.DragMove,
            coordinates: H
          });
        },
        onEnd: fe(M.DragEnd),
        onCancel: fe(M.DragCancel)
      });
      xe(() => {
        mt(q), wt(z.nativeEvent);
      });
      function fe(H) {
        return async function() {
          const {
            active: me,
            collisions: Fe,
            over: je,
            scrollAdjustedTranslate: zt
          } = ie.current;
          let we = null;
          if (me && zt) {
            const {
              cancelDrop: De
            } = ve.current;
            we = {
              activatorEvent: U,
              active: me,
              collisions: Fe,
              delta: zt,
              over: je
            }, H === M.DragEnd && typeof De == "function" && await Promise.resolve(De(we)) && (H = M.DragCancel);
          }
          Z.current = null, xe(() => {
            b({
              type: H
            }), S(re.Uninitialized), Lt(null), mt(null), wt(null);
            const De = H === M.DragEnd ? "onDragEnd" : "onDragCancel";
            if (we) {
              const tt = ve.current[De];
              tt == null || tt(we), w({
                type: De,
                event: we
              });
            }
          });
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [m]
  ), yn = P((z, F) => (j, ne) => {
    const X = j.nativeEvent, U = m.get(ne);
    if (
      // Another sensor is already instantiating
      Z.current !== null || // No active draggable
      !U || // Event has already been captured
      X.dndKit || X.defaultPrevented
    )
      return;
    const q = {
      active: U
    };
    z(j, F.options, q) === !0 && (X.dndKit = {
      capturedBy: F.sensor
    }, Z.current = ne, It(j, F));
  }, [m, It]), kt = vr(d, yn);
  Cr(d), J(() => {
    W && L === re.Initializing && S(re.Initialized);
  }, [W, L]), N(
    () => {
      const {
        onDragMove: z
      } = ve.current, {
        active: F,
        activatorEvent: j,
        collisions: ne,
        over: X
      } = ie.current;
      if (!F || !j)
        return;
      const U = {
        active: F,
        activatorEvent: j,
        collisions: ne,
        delta: {
          x: ue.x,
          y: ue.y
        },
        over: X
      };
      xe(() => {
        z == null || z(U), w({
          type: "onDragMove",
          event: U
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ue.x, ue.y]
  ), N(
    () => {
      const {
        active: z,
        activatorEvent: F,
        collisions: j,
        droppableContainers: ne,
        scrollAdjustedTranslate: X
      } = ie.current;
      if (!z || Z.current == null || !F || !X)
        return;
      const {
        onDragOver: U
      } = ve.current, q = ne.get(Mt), fe = q && q.rect.current ? {
        id: q.id,
        rect: q.rect.current,
        data: q.data,
        disabled: q.disabled
      } : null, H = {
        active: z,
        activatorEvent: F,
        collisions: j,
        delta: {
          x: X.x,
          y: X.y
        },
        over: fe
      };
      xe(() => {
        Lt(fe), U == null || U(H), w({
          type: "onDragOver",
          event: H
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Mt]
  ), J(() => {
    ie.current = {
      activatorEvent: Q,
      active: _,
      activeNode: K,
      collisionRect: de,
      collisions: be,
      droppableRects: le,
      draggableNodes: m,
      draggingNode: ze,
      draggingNodeRect: ce,
      droppableContainers: k,
      over: te,
      scrollableAncestors: ee,
      scrollAdjustedTranslate: ue
    }, G.current = {
      initial: ce,
      translated: de
    };
  }, [_, K, be, de, m, ze, ce, le, k, te, ee, ue]), fr({
    ...Ct,
    delta: O,
    draggingRect: de,
    pointerCoordinates: Nt,
    scrollableAncestors: ee,
    scrollableAncestorRects: Pe
  });
  const mn = R(() => ({
    active: _,
    activeNode: K,
    activeNodeRect: W,
    activatorEvent: Q,
    collisions: be,
    containerNodeRect: Ze,
    dragOverlay: se,
    draggableNodes: m,
    droppableContainers: k,
    droppableRects: le,
    over: te,
    measureDroppableContainers: ke,
    scrollableAncestors: ee,
    scrollableAncestorRects: Pe,
    measuringConfiguration: oe,
    measuringScheduled: xt,
    windowRect: et
  }), [_, K, W, Q, be, Ze, se, m, k, le, te, ke, ee, Pe, oe, xt, et]), wn = R(() => ({
    activatorEvent: Q,
    activators: kt,
    active: _,
    activeNodeRect: W,
    ariaDescribedById: {
      draggable: Qe
    },
    dispatch: b,
    draggableNodes: m,
    over: te,
    measureDroppableContainers: ke
  }), [Q, kt, _, W, b, Qe, m, te, ke]);
  return A.createElement(Jt.Provider, {
    value: y
  }, A.createElement(Ie.Provider, {
    value: wn
  }, A.createElement(fn.Provider, {
    value: mn
  }, A.createElement(_e.Provider, {
    value: bn
  }, c)), A.createElement(Ir, {
    disabled: (a == null ? void 0 : a.restoreFocus) === !1
  })), A.createElement(jn, {
    ...a,
    hiddenTextDescribedById: Qe
  }));
  function Dn() {
    const z = (yt == null ? void 0 : yt.autoScrollEnabled) === !1, F = typeof l == "object" ? l.enabled === !1 : l === !1, j = C && !z && !F;
    return typeof l == "object" ? {
      ...l,
      enabled: j
    } : {
      enabled: j
    };
  }
}), Pr = /* @__PURE__ */ Ne(null), Ut = "button", Br = "Droppable";
function oo(e) {
  let {
    id: t,
    data: n,
    disabled: r = !1,
    attributes: o
  } = e;
  const i = Je(Br), {
    activators: s,
    activatorEvent: a,
    active: l,
    activeNodeRect: c,
    ariaDescribedById: d,
    draggableNodes: u,
    over: g
  } = ae(Ie), {
    role: f = Ut,
    roleDescription: D = "draggable",
    tabIndex: h = 0
  } = o ?? {}, p = (l == null ? void 0 : l.id) === t, b = ae(p ? _e : Pr), [w, y] = Ye(), [L, S] = Ye(), C = Rr(s, t), v = Ee(n);
  J(
    () => (u.set(t, {
      id: t,
      key: i,
      node: w,
      activatorNode: L,
      data: v
    }), () => {
      const O = u.get(t);
      O && O.key === i && u.delete(t);
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [u, t]
  );
  const m = R(() => ({
    role: f,
    tabIndex: h,
    "aria-disabled": r,
    "aria-pressed": p && f === Ut ? !0 : void 0,
    "aria-roledescription": D,
    "aria-describedby": d.draggable
  }), [r, f, h, p, D, d.draggable]);
  return {
    active: l,
    activatorEvent: a,
    activeNodeRect: c,
    attributes: m,
    isDragging: p,
    listeners: r ? void 0 : C,
    node: w,
    over: g,
    setNodeRef: y,
    setActivatorNodeRef: S,
    transform: b
  };
}
function Fr() {
  return ae(fn);
}
const jr = "Droppable", Xr = {
  timeout: 25
};
function io(e) {
  let {
    data: t,
    disabled: n = !1,
    id: r,
    resizeObserverConfig: o
  } = e;
  const i = Je(jr), {
    active: s,
    dispatch: a,
    over: l,
    measureDroppableContainers: c
  } = ae(Ie), d = E({
    disabled: n
  }), u = E(!1), g = E(null), f = E(null), {
    disabled: D,
    updateMeasurementsFor: h,
    timeout: p
  } = {
    ...Xr,
    ...o
  }, b = Ee(h ?? r), w = P(
    () => {
      if (!u.current) {
        u.current = !0;
        return;
      }
      f.current != null && clearTimeout(f.current), f.current = setTimeout(() => {
        c(Array.isArray(b.current) ? b.current : [b.current]), f.current = null;
      }, p);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [p]
  ), y = Ge({
    callback: w,
    disabled: D || !s
  }), L = P((m, O) => {
    y && (O && (y.unobserve(O), u.current = !1), m && y.observe(m));
  }, [y]), [S, C] = Ye(L), v = Ee(t);
  return N(() => {
    !y || !S.current || (y.disconnect(), u.current = !1, y.observe(S.current));
  }, [S, y]), J(
    () => (a({
      type: M.RegisterDroppable,
      element: {
        id: r,
        key: i,
        disabled: n,
        node: S,
        rect: g,
        data: v
      }
    }), () => a({
      type: M.UnregisterDroppable,
      key: i,
      id: r
    })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [r]
  ), N(() => {
    n !== d.current.disabled && (a({
      type: M.SetDroppableDisabled,
      id: r,
      key: i,
      disabled: n
    }), d.current.disabled = n);
  }, [r, i, n, a]), {
    active: s,
    rect: g,
    isOver: (l == null ? void 0 : l.id) === r,
    node: S,
    over: l,
    setNodeRef: C
  };
}
function Yr(e) {
  let {
    animation: t,
    children: n
  } = e;
  const [r, o] = $(null), [i, s] = $(null), a = $e(n);
  return !n && !r && a && o(a), J(() => {
    if (!i)
      return;
    const l = r == null ? void 0 : r.key, c = r == null ? void 0 : r.props.id;
    if (l == null || c == null) {
      o(null);
      return;
    }
    Promise.resolve(t(c, i)).then(() => {
      o(null);
    });
  }, [t, r, i]), A.createElement(A.Fragment, null, n, r ? Rn(r, {
    ref: s
  }) : null);
}
const $r = {
  x: 0,
  y: 0,
  scaleX: 1,
  scaleY: 1
};
function Kr(e) {
  let {
    children: t
  } = e;
  return A.createElement(Ie.Provider, {
    value: dn
  }, A.createElement(_e.Provider, {
    value: $r
  }, t));
}
const Wr = {
  position: "fixed",
  touchAction: "none"
}, Ur = (e) => ht(e) ? "transform 250ms ease" : void 0, Hr = /* @__PURE__ */ Cn((e, t) => {
  let {
    as: n,
    activatorEvent: r,
    adjustScale: o,
    children: i,
    className: s,
    rect: a,
    style: l,
    transform: c,
    transition: d = Ur
  } = e;
  if (!a)
    return null;
  const u = o ? c : {
    ...c,
    scaleX: 1,
    scaleY: 1
  }, g = {
    ...Wr,
    width: a.width,
    height: a.height,
    top: a.top,
    left: a.left,
    transform: Oe.Transform.toString(u),
    transformOrigin: o && r ? Xn(r, a) : void 0,
    transition: typeof d == "function" ? d(r) : d,
    ...l
  };
  return A.createElement(n, {
    className: s,
    style: g,
    ref: t
  }, i);
}), Vr = (e) => (t) => {
  let {
    active: n,
    dragOverlay: r
  } = t;
  const o = {}, {
    styles: i,
    className: s
  } = e;
  if (i != null && i.active)
    for (const [a, l] of Object.entries(i.active))
      l !== void 0 && (o[a] = n.node.style.getPropertyValue(a), n.node.style.setProperty(a, l));
  if (i != null && i.dragOverlay)
    for (const [a, l] of Object.entries(i.dragOverlay))
      l !== void 0 && r.node.style.setProperty(a, l);
  return s != null && s.active && n.node.classList.add(s.active), s != null && s.dragOverlay && r.node.classList.add(s.dragOverlay), function() {
    for (const [l, c] of Object.entries(o))
      n.node.style.setProperty(l, c);
    s != null && s.active && n.node.classList.remove(s.active);
  };
}, qr = (e) => {
  let {
    transform: {
      initial: t,
      final: n
    }
  } = e;
  return [{
    transform: Oe.Transform.toString(t)
  }, {
    transform: Oe.Transform.toString(n)
  }];
}, Jr = {
  duration: 250,
  easing: "ease",
  keyframes: qr,
  sideEffects: /* @__PURE__ */ Vr({
    styles: {
      active: {
        opacity: "0"
      }
    }
  })
};
function Gr(e) {
  let {
    config: t,
    draggableNodes: n,
    droppableContainers: r,
    measuringConfiguration: o
  } = e;
  return qe((i, s) => {
    if (t === null)
      return;
    const a = n.get(i);
    if (!a)
      return;
    const l = a.node.current;
    if (!l)
      return;
    const c = un(s);
    if (!c)
      return;
    const {
      transform: d
    } = B(s).getComputedStyle(s), u = _t(d);
    if (!u)
      return;
    const g = typeof t == "function" ? t : _r(t);
    return on(l, o.draggable.measure), g({
      active: {
        id: i,
        data: a.data,
        node: l,
        rect: o.draggable.measure(l)
      },
      draggableNodes: n,
      dragOverlay: {
        node: s,
        rect: o.dragOverlay.measure(c)
      },
      droppableContainers: r,
      measuringConfiguration: o,
      transform: u
    });
  });
}
function _r(e) {
  const {
    duration: t,
    easing: n,
    sideEffects: r,
    keyframes: o
  } = {
    ...Jr,
    ...e
  };
  return (i) => {
    let {
      active: s,
      dragOverlay: a,
      transform: l,
      ...c
    } = i;
    if (!t)
      return;
    const d = {
      x: a.rect.left - s.rect.left,
      y: a.rect.top - s.rect.top
    }, u = {
      scaleX: l.scaleX !== 1 ? s.rect.width * l.scaleX / a.rect.width : 1,
      scaleY: l.scaleY !== 1 ? s.rect.height * l.scaleY / a.rect.height : 1
    }, g = {
      x: l.x - d.x,
      y: l.y - d.y,
      ...u
    }, f = o({
      ...c,
      active: s,
      dragOverlay: a,
      transform: {
        initial: l,
        final: g
      }
    }), [D] = f, h = f[f.length - 1];
    if (JSON.stringify(D) === JSON.stringify(h))
      return;
    const p = r == null ? void 0 : r({
      active: s,
      dragOverlay: a,
      ...c
    }), b = a.node.animate(f, {
      duration: t,
      easing: n,
      fill: "forwards"
    });
    return new Promise((w) => {
      b.onfinish = () => {
        p == null || p(), w();
      };
    });
  };
}
let Ht = 0;
function Qr(e) {
  return R(() => {
    if (e != null)
      return Ht++, Ht;
  }, [e]);
}
const so = /* @__PURE__ */ A.memo((e) => {
  let {
    adjustScale: t = !1,
    children: n,
    dropAnimation: r,
    style: o,
    transition: i,
    modifiers: s,
    wrapperElement: a = "div",
    className: l,
    zIndex: c = 999
  } = e;
  const {
    activatorEvent: d,
    active: u,
    activeNodeRect: g,
    containerNodeRect: f,
    draggableNodes: D,
    droppableContainers: h,
    dragOverlay: p,
    over: b,
    measuringConfiguration: w,
    scrollableAncestors: y,
    scrollableAncestorRects: L,
    windowRect: S
  } = Fr(), C = ae(_e), v = Qr(u == null ? void 0 : u.id), m = hn(s, {
    activatorEvent: d,
    active: u,
    activeNodeRect: g,
    containerNodeRect: f,
    draggingNodeRect: p.rect,
    over: b,
    overlayNodeRect: p.rect,
    scrollableAncestors: y,
    scrollableAncestorRects: L,
    transform: C,
    windowRect: S
  }), O = bt(g), k = Gr({
    config: r,
    draggableNodes: D,
    droppableContainers: h,
    measuringConfiguration: w
  }), T = O ? p.setRef : void 0;
  return A.createElement(Kr, null, A.createElement(Yr, {
    animation: k
  }, u && v ? A.createElement(Hr, {
    key: v,
    id: u.id,
    ref: T,
    as: a,
    activatorEvent: d,
    adjustScale: t,
    className: l,
    transition: i,
    rect: O,
    style: {
      zIndex: c,
      ...o
    },
    transform: m
  }, n) : null));
});
export {
  ro as D,
  ur as M,
  ln as P,
  dr as T,
  io as a,
  to as b,
  no as c,
  so as d,
  We as g,
  oo as u
};
