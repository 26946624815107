import { j as s } from "./jsx-runtime-D0AQ1Mn7.js";
import "./Accordion-6RJGoeje.js";
import "./Avatar-DNKFm3wh.js";
import { B } from "./Badge-QBVLNL8O.js";
import "./Button-BOVHAiPs.js";
import { D as n } from "./DropdownMenu-BmUInubt.js";
import { I as c } from "./Icon-CtH7buGH.js";
import "./Input-CyIITBNc.js";
import "./Label-BtfPjSeJ.js";
import { T as p } from "./Text-B4fIcnEL.js";
import "./Tooltip-ChPGW92B.js";
import { g as a } from "./utils-CJ9afRe1.js";
import { S as r } from "./Stack-BZqOy47V.js";
import "./Switch-D2_ymNuk.js";
import "./Toast-D1jXuM_f.js";
import "react";
import "./Toggle-BDJtD566.js";
const e = ({
  children: o,
  className: t = ""
}) => {
  const m = a(o, "Top"), i = a(o, "Bottom");
  return /* @__PURE__ */ s.jsxs(
    "div",
    {
      className: `${t} bg-gray-100 flex flex-col rounded-lg overflow-hidden w-fit`,
      children: [
        m,
        i
      ]
    }
  );
}, l = ({ children: o, className: t }) => {
  const m = a(o, "Image"), i = a(o, "Badge");
  return /* @__PURE__ */ s.jsxs("div", { className: `relative ${t}`, children: [
    i,
    m
  ] });
};
l.displayName = "Top";
e.Top = l;
const d = ({
  children: o,
  className: t
}) => /* @__PURE__ */ s.jsx(
  B,
  {
    className: `${t} absolute right-2 top-2`,
    variant: "destructive",
    children: o
  }
);
d.displayName = "Badge";
e.Badge = d;
const x = ({
  src: o,
  width: t = 170,
  height: m = 130,
  className: i = ""
}) => /* @__PURE__ */ s.jsx("div", { className: `${i} px-12 pt-8`, children: /* @__PURE__ */ s.jsx("img", { src: o, width: t, height: m }) });
x.displayName = "Image";
e.Image = x;
const g = ({ children: o, className: t = "" }) => {
  const m = a(o, "Icon"), i = a(o, "Name"), I = a(o, "Info"), y = a(o, "Actions");
  return /* @__PURE__ */ s.jsx("div", { className: `bg-white w-full flex py-3.5 pl-3.5 pr-2.5 ${t}`, children: /* @__PURE__ */ s.jsxs(r, { row: !0, space: "sm", className: "w-full", children: [
    m,
    /* @__PURE__ */ s.jsxs(r, { space: "xs", children: [
      i,
      I
    ] }),
    y
  ] }) });
};
g.displayName = "Bottom";
e.Bottom = g;
const N = ({ name: o }) => /* @__PURE__ */ s.jsx(c, { name: o });
N.displayName = "Icon";
e.Icon = N;
const f = ({ children: o }) => /* @__PURE__ */ s.jsx(p, { size: "sm", children: o });
f.displayName = "Name";
e.Name = f;
const j = ({ children: o }) => /* @__PURE__ */ s.jsx(p, { size: "xs", variant: "secondary", children: o });
j.displayName = "Info";
e.Info = j;
const u = ({ children: o }) => /* @__PURE__ */ s.jsx("span", { className: "ml-auto mb-auto", children: /* @__PURE__ */ s.jsxs(n, { children: [
  /* @__PURE__ */ s.jsx(n.Trigger, { children: /* @__PURE__ */ s.jsx(c, { name: "MoreHoriz", width: 24 }) }),
  o
] }) });
u.displayName = "Actions";
e.Actions = u;
export {
  e as D
};
