import { j as e } from "./jsx-runtime-D0AQ1Mn7.js";
import { forwardRef as P } from "react";
import { B as k } from "./Button-BOVHAiPs.js";
import { C as O } from "./Checkbox.component-Bg1oKN6R.js";
import { D as v } from "./DropdownMenu-BmUInubt.js";
import { I as r } from "./Icon-CtH7buGH.js";
import { P as R } from "./ProgressBar-DB_RPe3H.js";
import { S as N } from "./Stack-BZqOy47V.js";
import { T as i } from "./Text-B4fIcnEL.js";
import { T as h } from "./Tooltip-ChPGW92B.js";
import { u as E } from "./useMediaQuery-BLIs2rKV.js";
import { F as S } from "./FileIcon.component-vbYjyvoz.js";
import { c as L } from "./utils-CJ9afRe1.js";
import { S as t } from "./ScreenSizeDropdownMenu.component-uCT6jkGh.js";
import { p as U } from "./parseISO-DDLHk1Pe.js";
import { f as C } from "./format-DTUgKQ74.js";
const A = (l) => l.isDesktop ? {
  withPortal: l.withPortal,
  align: "start",
  side: "bottom",
  alignOffset: 0
} : {}, ie = P((l, a) => {
  const o = E("(min-width: 768px)"), { className: n, ...d } = l, {
    checked: c,
    isDragging: M,
    onCancelUpload: y,
    onCheckFilesFromOrToFile: z,
    onMove: m,
    onDelete: I,
    onOpenInNewTab: D,
    onMoveToMyShoeBox: g,
    onDoubleClick: p,
    onClick: u,
    onDownload: f,
    onCopy: j,
    onRename: W,
    onSendMessage: w,
    onCopyLinks: b,
    file: s,
    withPortal: T,
    onCheckChange: F,
    ...B
  } = d;
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      "aria-checked": c,
      "data-dragged": M,
      className: L(
        "flex data-[dragged=true]:opacity-10  aria-checked:bg-black-20 px-1 gap-1 bg-black-10 hover:bg-black-20 group rounded-md transition-colors items-center",
        n
      ),
      children: [
        /* @__PURE__ */ e.jsxs(
          "div",
          {
            className: "flex flex-col flex-1 overflow-hidden h-11 py-1.5 group/icon",
            ref: a,
            ...B,
            children: [
              /* @__PURE__ */ e.jsxs("div", { className: "flex items-center gap-2 flex-1  shrink-0  justify-center", children: [
                /* @__PURE__ */ e.jsx(
                  "button",
                  {
                    className: "hidden lg:flex items-center cursor-move text-gray-neutral-100  opacity-0 h-7 group-hover:opacity-100 transition-opacity",
                    children: /* @__PURE__ */ e.jsx(r, { name: "DragHandle", width: 8, height: 14, className: "shrink-0" })
                  }
                ),
                /* @__PURE__ */ e.jsx(
                  O,
                  {
                    className: "bg-white opacity-0 group-hover/icon:opacity-100 data-active:opacity-100",
                    checked: c,
                    size: "sm",
                    "data-ui": c ? "active" : "",
                    "data-testid": "multi-select-checkbox",
                    onClick: (x) => {
                      x.shiftKey && (z(s), x.preventDefault());
                    },
                    onCheckedChange: (x) => F(x, s)
                  }
                ),
                /* @__PURE__ */ e.jsx(S, { fileName: s.documentName, className: "shrink-0" }),
                /* @__PURE__ */ e.jsx(h.Provider, { children: /* @__PURE__ */ e.jsxs(
                  h,
                  {
                    delayDuration: 1e3,
                    children: [
                      /* @__PURE__ */ e.jsx(h.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
                        i,
                        {
                          select: !1,
                          tabIndex: 0,
                          role: "button",
                          onDoubleClick: () => p == null ? void 0 : p(s),
                          onClick: () => u == null ? void 0 : u(s),
                          size: "sm",
                          className: "flex-1 inline-flex items-center min-w-0",
                          children: /* @__PURE__ */ e.jsx("span", { className: "truncate", children: s.title })
                        }
                      ) }),
                      /* @__PURE__ */ e.jsx(h.Portal, { children: /* @__PURE__ */ e.jsx(h.Content, { children: s.title }) })
                    ]
                  }
                ) })
              ] }),
              s.uploading && /* @__PURE__ */ e.jsx(
                R,
                {
                  height: "h-1",
                  progressBackGroundColor: "bg-gray-neutral-35",
                  className: "ml-9 flex-1 flex",
                  showLegend: !1,
                  items: [
                    {
                      title: "",
                      value: s.uploadProgress
                    }
                  ]
                }
              )
            ]
          }
        ),
        s.uploading && /* @__PURE__ */ e.jsx(
          k,
          {
            title: "Cancel upload",
            "aria-label": "Cancel upload",
            onClick: () => y(s),
            variant: "ghost",
            className: "inline-flex items-center",
            size: "sm",
            children: /* @__PURE__ */ e.jsx(r, { strokeWidth: 1.5, name: "Cancel", width: 20, height: 20 })
          }
        ),
        !s.uploading && /* @__PURE__ */ e.jsxs("div", { className: "lg:min-w-0 lg:w-0 justify-end group-hover:w-auto flex items-center overflow-hidden gap-2 shrink-0", children: [
          m && /* @__PURE__ */ e.jsx(
            k,
            {
              title: "Move file",
              "aria-label": "Move file",
              onClick: () => m(s),
              variant: "secondary",
              className: "hidden sm:inline-flex rounded-full bg-transparent hover:bg-gray-neutral-50 w-6 h-6 focus:bg-gray-neutral-50 p-0.5",
              children: /* @__PURE__ */ e.jsx(r, { strokeWidth: 1.5, name: "FolderMoveUp", width: 17, height: 14 })
            }
          ),
          /* @__PURE__ */ e.jsxs(t, { modal: !1, children: [
            /* @__PURE__ */ e.jsx(t.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
              k,
              {
                title: "More options",
                "aria-label": "More options",
                variant: "secondary",
                className: "rounded-full hover:bg-gray-neutral-50 w-6 h-6 focus:bg-gray-neutral-50 p-0.5 bg-transparent inline-flex",
                children: /* @__PURE__ */ e.jsx(r, { strokeWidth: 2, name: "MoreVert", width: 20, height: 20 })
              }
            ) }),
            /* @__PURE__ */ e.jsxs(
              t.Content,
              {
                ...A({ isDesktop: o, withPortal: T }),
                className: "z-dialog gap-2 min-w-72",
                children: [
                  !o && /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
                    /* @__PURE__ */ e.jsxs(
                      "div",
                      {
                        className: "flex gap-2 h-9items-center rounded-md px-2 pt-2",
                        children: [
                          /* @__PURE__ */ e.jsx(S, { fileName: s.documentName, className: "shrink-0" }),
                          /* @__PURE__ */ e.jsx(i, { size: "sm", truncate: !0, className: "flex-1", children: s.title })
                        ]
                      }
                    ),
                    /* @__PURE__ */ e.jsx(v.Separator, {})
                  ] }),
                  /* @__PURE__ */ e.jsxs(
                    t.Item,
                    {
                      onSelect: () => D(s),
                      className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                      children: [
                        /* @__PURE__ */ e.jsx(r, { name: "MultiWindow", strokeWidth: 2, width: 18, height: 18 }),
                        " ",
                        /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Open in New Tab" })
                      ]
                    }
                  ),
                  /* @__PURE__ */ e.jsxs(
                    t.Item,
                    {
                      onSelect: () => W(s),
                      className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                      children: [
                        /* @__PURE__ */ e.jsx(r, { name: "Edit", strokeWidth: 2, width: 18, height: 18 }),
                        " ",
                        /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Rename" })
                      ]
                    }
                  ),
                  /* @__PURE__ */ e.jsx(v.Separator, {}),
                  (m || j || g) && /* @__PURE__ */ e.jsxs(t.Sub, { children: [
                    /* @__PURE__ */ e.jsx(t.SubTrigger, { className: "gap-2 h-9 flex items-center px-2", asChild: !0, children: /* @__PURE__ */ e.jsxs(N, { row: !0, space: "sm", className: "flex-1 font-medium text-sm", children: [
                      /* @__PURE__ */ e.jsx(r, { name: "ArrowEmailForward", strokeWidth: 2, width: 18, height: 18 }),
                      "Share"
                    ] }) }),
                    /* @__PURE__ */ e.jsxs(
                      t.SubContent,
                      {
                        className: "z-dialog gap-2 max-w-full sm:w-72 flex flex-col",
                        children: [
                          m && /* @__PURE__ */ e.jsxs(
                            t.Item,
                            {
                              onSelect: () => m(s),
                              className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                              children: [
                                /* @__PURE__ */ e.jsx(
                                  r,
                                  {
                                    name: "FolderMoveUp",
                                    strokeWidth: 2,
                                    width: 18,
                                    height: 18
                                  }
                                ),
                                /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Move to..." })
                              ]
                            }
                          ),
                          j && /* @__PURE__ */ e.jsxs(
                            t.Item,
                            {
                              onSelect: () => j(s),
                              className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                              children: [
                                /* @__PURE__ */ e.jsx(
                                  r,
                                  {
                                    name: "Copy",
                                    strokeWidth: 2,
                                    width: 18,
                                    height: 18
                                  }
                                ),
                                /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Copy to..." })
                              ]
                            }
                          ),
                          g && /* @__PURE__ */ e.jsxs(
                            t.Item,
                            {
                              onSelect: () => g(s),
                              className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                              children: [
                                /* @__PURE__ */ e.jsx(
                                  r,
                                  {
                                    name: "Box",
                                    strokeWidth: 2,
                                    width: 18,
                                    height: 18
                                  }
                                ),
                                /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Move to My Shoebox" })
                              ]
                            }
                          )
                        ]
                      }
                    )
                  ] }),
                  (w || f || b) && /* @__PURE__ */ e.jsxs(t.Sub, { children: [
                    /* @__PURE__ */ e.jsx(t.SubTrigger, { className: "gap-2 h-9 flex items-center px-2", asChild: !0, children: /* @__PURE__ */ e.jsxs(N, { row: !0, space: "sm", className: "flex-1 text-sm font-medium", children: [
                      /* @__PURE__ */ e.jsx(r, { name: "FolderOpenOutline", strokeWidth: 2, width: 18, height: 18 }),
                      "Organize"
                    ] }) }),
                    /* @__PURE__ */ e.jsxs(
                      t.SubContent,
                      {
                        className: "z-dialog gap-2 max-w-full sm:w-72 flex flex-col",
                        children: [
                          w && /* @__PURE__ */ e.jsxs(
                            t.Item,
                            {
                              onSelect: () => w(s),
                              className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                              children: [
                                /* @__PURE__ */ e.jsx(
                                  r,
                                  {
                                    name: "MessageText",
                                    strokeWidth: 2,
                                    width: 18,
                                    height: 18
                                  }
                                ),
                                /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Send Message" })
                              ]
                            }
                          ),
                          f && /* @__PURE__ */ e.jsxs(
                            t.Item,
                            {
                              onSelect: () => f(s),
                              className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                              children: [
                                /* @__PURE__ */ e.jsx(
                                  r,
                                  {
                                    name: "DownloadMenu",
                                    strokeWidth: 2,
                                    width: 18,
                                    height: 18
                                  }
                                ),
                                /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Download" })
                              ]
                            }
                          ),
                          b && /* @__PURE__ */ e.jsxs(
                            t.Item,
                            {
                              onSelect: () => b(s),
                              className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                              children: [
                                /* @__PURE__ */ e.jsx(
                                  r,
                                  {
                                    name: "Link",
                                    strokeWidth: 2,
                                    width: 18,
                                    height: 18
                                  }
                                ),
                                /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", children: "Copy Link" })
                              ]
                            }
                          )
                        ]
                      }
                    )
                  ] }),
                  /* @__PURE__ */ e.jsx(v.Separator, {}),
                  /* @__PURE__ */ e.jsxs(
                    t.Item,
                    {
                      onSelect: () => I(s),
                      className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2 text-destructive",
                      children: [
                        /* @__PURE__ */ e.jsx(r, { name: "Trash", strokeWidth: 2, width: 18, height: 18 }),
                        " ",
                        /* @__PURE__ */ e.jsx(i, { weight: "medium", size: "sm", variant: "inherit", children: "Remove" })
                      ]
                    }
                  )
                ]
              }
            )
          ] })
        ] })
      ]
    }
  );
}), ae = (l) => {
  const a = [];
  return l.forEach((o) => {
    let n = C(/* @__PURE__ */ new Date(), "MMM d, h:mm a");
    if (o.documentCreatedDate)
      try {
        n = C(U(o.documentCreatedDate), "MMM d, h:mm a");
      } catch {
      }
    const d = a.find((c) => c.title === n);
    d ? d.items.push(o) : a.push({ title: n, items: [o] });
  }), a;
}, oe = (l) => l.reduce((a, o) => {
  const n = o.message;
  return n && a.findIndex((d) => d === n) === -1 ? [
    ...a,
    n
  ] : a;
}, []);
export {
  ie as S,
  oe as a,
  ae as g
};
